Button{
    font-family: var(--font);
    letter-spacing: 1px;
}

.tabbar{
    display: flex;
    width: 100%;
    height: 40px;
    align-items:center;
    justify-content:space-around;
    background-color: #fff;
    margin-top: 10px;
   }
   .tabbar_item{
    display: flex;
    width: 30%;
    height: 40px;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    letter-spacing: 1px;
    font-family: var(--font);
   }
   .imges{
    margin-left: 5px;
    background-color:#EEFBF9;
    
 }

 .bookcard{
     /* height: 100vh; */
     width: 100%;
     overflow: scroll;
     align-items: center;
     scroll-behavior: smooth;
     justify-content: center;
     padding-bottom: 50px;
 }
 .sbookcard{
    position: relative;
    overflow: hidden;
   
}
 .bookcard_continer{
     position: relative;
     display: flex;
     flex-direction: row;
     background-color: #fff;
     box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
     border-radius: 5px;
     margin-top: 10px;
     margin-left: 7px;
     margin-right: 7px;
     margin-bottom: 5px;
    
 }
 .bookcover_continer{
  width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
 }

 .bookcover{
     width: 100%;
     min-height: 170px;
     /* height: 170px; */
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: #EEFBF9;
 }
 .skeletonbookcover{
    width: 100%;
    height: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .bookcontent{
     width: 100%;
     display: flex;
    flex-direction: column;
    margin-top: 4px;
   
 }
 .booktitle{
    font-family: var(--font);
     font-size: 18px;
     letter-spacing: 0.5px;
     color: #B11116;
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
 }
 .card-sub{
    font-family: var(--font);
     font-size: 11px;
     color:#161616;
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
 }
 .card-auth{
    font-family: var(--font);
    margin-top: 2px;
     font-size: 12px;
     color:#7e7c7c;
     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
 }
 .dot {
    height: 8px;
    width: 8px;
    margin-top: 2px;
    margin-right: 4px;
    background-color: rgb(180, 176, 176);
    border-radius: 50%;
    display: inline-block;
  }
  .card-dotdiv{
    margin-top: 4px;
    font-family: var(--font);
    font-size: 12px;
    color:#161616;
  }
  .card-sharerevo{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 6px;
    font-family: var(--font);
    font-size: 15px;
    color:#252525;
  }
  
  .favrem-icon{
    color: red;
    margin-right: 3px;
  }
  .shareicon{
    color: blue;
    margin-right: 3px;

  }
 .bookisbn{
    font-family: var(--font);
    margin-top: 2px;
   font-size: 11px;
   letter-spacing: 0.5px;
   color:#808080 ;
}
 .bookisbn span{
    font-family: var(--font);
    font-size: 11;
    color:#faa639 ;
 }
 .learnmore{
     color: #B11116;
     letter-spacing: 1px;
     font-size: 8px;
 }
 .deleteicon{
     font-size: 20px;
     margin-left: 0px;
     margin-top: 10px;
     color: rgb(180, 6, 6);
 }
 .loadingicon{
    font-size: 20px;
    margin-left: 10px;
    color: rgb(7, 47, 224);
 }

 .readbtncontiner{
     
     align-items: center;
 }

 .cards-container {
  display: flex;
  margin-left: 3px;
  padding: 5px;
  padding-bottom: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap;
  justify-content:left;
  background-color: #fff;
 }
  .ion-card {
    margin: 5px;
    width: calc(47%);
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;  
  }

  .sub-read{
    display: flex;
    height: 40px;
    align-items: center;
    justify-content:space-between;
    padding-left: 5px;
    padding-right: 5px;
    /* width: 100%; */
    margin-top: 10px;
    border: rgb(218, 216, 216) solid 1px;
    flex-direction: row;
}
.sub-read-name{
  font-family: var(--font);
  font-weight: bold;
  font-size: 12px;
  color:#070707 ;
}

.toc-icon {
  font-size: 19px;
  margin-top: 15px;
  margin-left: 15px;;
}
